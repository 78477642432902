import { object, string, boolean, AnySchema } from "yup";
import { TFunction } from "translations";
import buildErrorMsg from "../../Functional/validation/buildErrorMsg";
import buildMaxErrorMessage from "../../Functional/validation/buildMaxErrorMessage";
import { performerContactFallbackTranslations } from "./fallbackTranslations";

export const MAX_MESSAGE_LENGTH = 3000;
export const MAX_EMAIL_LENGTH = 254;
export const MAX_NAME_LENGTH = 250;
export const MAX_CONTACT_NO_LENGTH = 30;
export const MAX_COMPANY_NAME_LENGTH = 75;
export const MAX_COMPANY_WEBSITE_LENGTH = 75;

type ValidationSchemaOverrides = Partial<{
  senderName: AnySchema;
  senderEmail: AnySchema;
  senderTelephoneNumber: AnySchema;
  companyName: AnySchema;
  companyWebsite: AnySchema;
  message: AnySchema;
}>;

export const validationSchema = (
  t: TFunction,
  overrides?: ValidationSchemaOverrides
) => {
  const {
    yourName,
    yourEmail,
    emailValidation,
    yourMessage,
    yourContactNumberOptional,
    companyName,
    companyWebsiteSocialMedia,
    maxLengthMessage,
    required,
  } = performerContactFallbackTranslations;

  return object().shape({
    senderName: string()
      .max(
        MAX_NAME_LENGTH,
        buildMaxErrorMessage(maxLengthMessage, yourName, MAX_NAME_LENGTH)
      )
      .required(buildErrorMsg(required, yourName)),
    senderEmail: string()
      .email(buildErrorMsg(emailValidation, yourEmail))
      .max(
        MAX_EMAIL_LENGTH,
        buildMaxErrorMessage(maxLengthMessage, yourEmail, MAX_EMAIL_LENGTH)
      )
      .required(buildErrorMsg(required, yourEmail)),
    senderTelephoneNumber: string()
      .nullable()
      .max(
        MAX_CONTACT_NO_LENGTH,
        buildMaxErrorMessage(
          maxLengthMessage,
          yourContactNumberOptional,
          MAX_CONTACT_NO_LENGTH
        )
      ),
    companyName: string()
      .nullable()
      .max(
        MAX_COMPANY_NAME_LENGTH,
        buildMaxErrorMessage(
          maxLengthMessage,
          companyName,
          MAX_COMPANY_NAME_LENGTH
        )
      ),
    companyWebsite: string()
      .nullable()
      .max(
        MAX_COMPANY_WEBSITE_LENGTH,
        buildMaxErrorMessage(
          maxLengthMessage,
          companyWebsiteSocialMedia,
          MAX_COMPANY_WEBSITE_LENGTH
        )
      ),
    message: string()
      .max(
        MAX_MESSAGE_LENGTH,
        buildMaxErrorMessage(maxLengthMessage, yourMessage, MAX_MESSAGE_LENGTH)
      )
      .required(buildErrorMsg(required, yourMessage)),
    consent: boolean().isTrue(t("common.validation.acknowledge")),
    ...overrides,
  });
};
