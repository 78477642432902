export const profile = {
  "MainPage.AvailabilityCalendarForm.AvailabilityCalendarForm.datePicker":
    "availability-calendar-datepicker",
  "MainPage.AvailabilityCalendarForm.AvailabilityCalendarForm.titleInput":
    "availability-calendar-title-input",
  "MainPage.RowGallery.RowGallery.editButton": "RowGallery_EditButton",
  "MainPage.RowGallery.RowGallery.photo": "RowGallery_Photo",
  "ProfileStatsPage.HelpTip.helpTipContent": "help-tip-content",
  "ProfileStatsPage.StatCard.totalViewsCount": "total-views-count",
};
