import React from "react";
import Cropper from "react-cropper";
import { Spinner, Stub } from "component-library";

import { ImageMetadataManager } from "../../../utils/metadata";

import ToolSettings, {
  IToolSettingsTexts,
} from "./components/ToolSettings/ToolSettings";
import { ToolBar } from "./components/ToolBar/ToolBar";
import { Tool } from "./components/ToolBar/Tool";
import { PhotoToolState } from "./PhotoToolState";
import { HistoryRecord } from "./HistoryRecord";
import { ToolState } from "./ToolState";
import { History } from "./History";

import "./PhotoTool.scss";
import "./Cropper.scss";

export interface IPhotoToolTexts {
  photoEditor: {
    toolNames: {
      contrast: string;
      crop: string;
      tilt: string;
      rotate: string;
      undo: string;
      brightness: string;
    };
  };
  tools: IToolSettingsTexts;
}
export const IPhotoToolTextsKeys = ["photoEditor", "tools"];

export interface IPhotoToolProps {
  // Object of photo.
  photo: IPhotoModel | null;
  // State of photo tool.
  photoToolData: PhotoToolState | null;
  // Texts for controls
  texts: IPhotoToolTexts;
  // Type of image. Needs for correct saving without blowing size and keeping quality of image.
  imageType: string;
  // Class name which can use for style overriding.
  className?: string;
  // Cropper settings
  cropperSettings?: any;
  // Validation function for 'apply' operation
  validateApply?: (canvas: HTMLCanvasElement) => boolean;
  // Error
  error: string | null;
  // Function for validating cropping
  cropValidation?: (
    event: CustomEvent,
    cropper: any
  ) => { isValid: boolean; message: string };
  // Logger method
  logError?: (error: Error) => void;
  preventCrop?: boolean;
}

export interface IPhotoToolState {
  occupiedHeight: number;
  selectedToolId: string;
  toolState: ToolState;
  minPhotoHeight: number;
  originalPhoto: IPhotoModel | null;
  currentPhoto: IPhotoModel | null;
  undoDisabled: boolean;
  isCropperReady: boolean;
  isValidCrop: boolean;
  cropValidationMessage: string;
}

export interface IPhotoModel {
  url: string;
}

export const TOOL_IDS = {
  contrast: "contrast",
  tilt: "tilt",
  rotate: "rotate",
  brightness: "brightness",
  crop: "crop",
};

const PHOTO_FILTERS = [TOOL_IDS.contrast, TOOL_IDS.brightness];

const INITIAL_TOOL_STATE = {
  contrast: 100,
  tilt: 0,
  rotate: 0,
  crop: { zoom: 0 },
  brightness: 100,
};

const MIN_PHOTO_HEIGHT = 200;
const MAX_CROPPER_DIMENSIONS = 4096;

export class PhotoTool extends React.Component<
  IPhotoToolProps,
  IPhotoToolState
> {
  private imageMetadataManager: ImageMetadataManager =
    new ImageMetadataManager();
  private cropperRef: React.RefObject<Cropper> = React.createRef<Cropper>();
  private history: History;
  private timerIndex: any;
  private isDelayApplying = false;
  private isInitPhotoToolData = false;
  private verticalPivot: number = 0;
  private horizontalPivot: number = 0;
  private selectedMode = "";

  private applyFullSizeCropperBox = true;

  private previousToolState: ToolState = INITIAL_TOOL_STATE;
  private previousTool: string;
  public runZooming: boolean;

  private get cropper() {
    return this.cropperRef.current;
  }

  public get isReady(): boolean {
    return this.state.isCropperReady;
  }

  constructor(props: IPhotoToolProps) {
    super(props);

    const state: IPhotoToolState = {
      occupiedHeight: 0,
      selectedToolId: TOOL_IDS.crop,
      minPhotoHeight: MIN_PHOTO_HEIGHT,
      toolState: INITIAL_TOOL_STATE,
      originalPhoto: props.photo,
      currentPhoto: props.photo,
      undoDisabled: true,
      isCropperReady: false,
      isValidCrop: true,
      cropValidationMessage: "",
    };

    if (props.photoToolData) {
      state.toolState = props.photoToolData.toolState;
      this.history = props.photoToolData.history;
    }

    this.state = state;
  }

  public render() {
    this.setFilter();
    const { texts, className, cropperSettings, error } = this.props;
    const {
      isValidCrop,
      cropValidationMessage,
      currentPhoto,
      isCropperReady,
      undoDisabled,
      selectedToolId,
      toolState,
    } = this.state;

    const rootClassName = `c-photo-tool ${className || ""}`;
    const isValidPhotoUrl = Boolean(currentPhoto && currentPhoto.url);
    const isSpinnerVisible = !(
      Boolean(error) ||
      (isCropperReady && isValidPhotoUrl)
    );
    const isActionsDisabled =
      Boolean(error) || !isValidPhotoUrl || !isCropperReady;

    return (
      <div className={rootClassName}>
        {!isValidCrop && (
          <div className="c-photo-tool__cropper-validation">
            {cropValidationMessage}
          </div>
        )}
        <div className="c-photo-tool__image">
          {currentPhoto && (
            <Cropper
              ref={this.cropperRef}
              src={currentPhoto.url}
              guides={false}
              zoomOnTouch={false}
              zoomOnWheel={false}
              viewMode={1}
              ready={this.cropperReadyEvent}
              dragMode={"move"}
              crop={this.cropEvent}
              {...cropperSettings}
            />
          )}
          {isSpinnerVisible && <Spinner />}
          {error && <Stub text={error} />}
        </div>
        <div className="c-photo-tool__tools">
          <div className="c-photo-tool__tool-settings">
            <ToolSettings
              disabledTools={isActionsDisabled}
              toolState={toolState}
              changeState={this.changeToolState}
              toolId={selectedToolId}
              texts={texts.tools}
              preventCrop={this.props.preventCrop}
            />
          </div>
          <div className="c-photo-tool__tool-bar">
            <ToolBar
              disablePanel={isActionsDisabled}
              undoDisabled={undoDisabled}
              toolId={selectedToolId}
              undo={this.undo}
              texts={{ undo: texts.photoEditor.toolNames.undo }}
              tools={this.getTools()}
              selectTool={this.selectTool}
            />
          </div>
        </div>
      </div>
    );
  }

  public async componentDidMount() {
    this.configWorkspace();
    await this.saveImageMetadata(this.props);
  }

  public componentWillUnmount() {
    this.timerIndex && clearTimeout(this.timerIndex);
  }

  public async componentWillReceiveProps(nextProps: IPhotoToolProps) {
    const { originalPhoto } = this.state;
    const { photo } = nextProps;

    if (!(originalPhoto && originalPhoto.url) && photo && photo.url) {
      await this.saveImageMetadata(nextProps);
      this.setState({ originalPhoto: photo, currentPhoto: photo });
    }
  }

  public getHistoryCopy = () => {
    let historyCopy: HistoryRecord[] = [];

    if (this.history) {
      historyCopy = this.history.getHistoryCopy();
    }

    return historyCopy;
  };

  public getPhotoToolState = () => {
    return this.preparePhotoToolState();
  };

  public cropEvent = (event: CustomEvent) => {
    const { cropValidation } = this.props;
    const { height, width } = event.detail;

    const isCropToolSelected = this.state.selectedToolId === TOOL_IDS.crop;
    const isCorrectEvent = width !== 0 && height !== 0 && !this.runZooming;

    if (cropValidation && isCropToolSelected && isCorrectEvent) {
      const validationResult = cropValidation(event, this.cropper);
      const message = validationResult.isValid ? "" : validationResult.message;

      this.setState({
        isValidCrop: validationResult.isValid,
        cropValidationMessage: message,
      });
    }
  };

  private saveImageMetadata = async (props: IPhotoToolProps) => {
    const { logError, photo } = props;
    const photoUrl = (photo && photo.url) || undefined;

    try {
      if (photoUrl) {
        const fetchResult = await fetch(photoUrl);
        const blob = await fetchResult.blob();

        await this.imageMetadataManager.saveImageBlobMetadata(blob);
      }
    } catch (error) {
      logError && logError(error);
    }
  };

  private cropperReadyEvent = () => {
    this.configWorkspace();

    this.horizontalPivot = this.getHorizontalPivot();
    this.verticalPivot = this.getVerticalPivot();

    if (this.isDelayApplying) {
      const record = this.history.getLastRecord();
      record.toolState.crop.isApplyCrop = false;
      this.applyPreviousHistoryRecord(record);
      this.isDelayApplying = false;
    }

    if (this.props.photoToolData && !this.isInitPhotoToolData) {
      const record = this.history.getLastRecord();
      record.toolState.crop.isApplyCrop = false;
      this.applyPreviousHistoryRecord(record);
      this.isDelayApplying = true;
      this.setState({ currentPhoto: this.props.photoToolData.image });
      this.isInitPhotoToolData = true;
      this.applyFullSizeCropperBox = false;
    }

    this.setState({ isCropperReady: true });

    if (this.applyFullSizeCropperBox) {
      this.applyFullSizeCropperBox = false;
      this.setFullSizeCropperBox();
    }

    if (!this.history) {
      this.initHistory();
    }
  };

  private undo = () => {
    const lastRecord = this.history.getLastRecord();
    const record = this.history.revert();
    if (lastRecord.image) {
      const lastCropChanges = this.history.getLastToolRecord(
        (toolState) => !!toolState.crop.isApplyCrop
      );
      if (lastCropChanges) {
        this.setState({ currentPhoto: { ...lastCropChanges.image } });
      } else {
        this.setState({ currentPhoto: this.state.originalPhoto });
      }
      this.isDelayApplying = true;
    } else {
      record.toolState.crop.isApplyCrop = false;
      this.applyPreviousHistoryRecord(record);
    }
    this.setState({ undoDisabled: this.history.isEmpty });
    this.previousTool = lastRecord.toolId;
  };

  private applyPreviousHistoryRecord = (record: HistoryRecord) => {
    if (this.cropper) {
      this.horizontalPivot = record.horizontalPivot;
      this.verticalPivot = record.verticalPivot;
      const selectedToolId =
        record.toolId === TOOL_IDS.rotate ? TOOL_IDS.crop : record.toolId;
      this.applyCrop(record.toolState);
      this.previousToolState = record.toolState;
      this.previousTool = record.toolId;
      this.cropper.clear();
      this.cropper.setDragMode("none");
      this.cropper.setData(record.cropperState);
      this.selectedMode = "";

      this.configWorkspace(selectedToolId);
      if ((record.toolState.rotate / 90) % 2 === 1) {
        this.cropper.zoomTo(
          this.verticalPivot + this.verticalPivot * record.toolState.crop.zoom
        );
      } else {
        this.cropper.zoomTo(
          this.horizontalPivot +
            this.horizontalPivot * record.toolState.crop.zoom
        );
      }

      this.setState({
        toolState: record.toolState,
        selectedToolId,
        undoDisabled: this.history.isEmpty,
      });
    }
  };

  private preparePhotoToolState = (): PhotoToolState | null => {
    let state: PhotoToolState | null = null;

    if (this.cropper) {
      this.cropper.clear();
      this.cropper.setDragMode("none");

      const newImage = this.prepareImage();
      if (this.state.originalPhoto && newImage) {
        state = new PhotoToolState();
        state.history = this.history;
        state.toolState = this.state.toolState;
        state.image = {
          ...this.state.originalPhoto,
          ...this.state.currentPhoto,
        };
        state.builtImage = { ...this.state.originalPhoto, ...newImage };
      }
    }

    return state;
  };

  private selectTool = (tool: Tool) => {
    if (
      tool.id !== this.state.selectedToolId &&
      tool.controlType !== "button"
    ) {
      this.setState({ selectedToolId: tool.id });
      this.configWorkspace(tool.id);
    } else if (tool.controlType === "button") {
      if (tool.id === TOOL_IDS.rotate) {
        const newState = new ToolState();
        newState.rotate = this.previousToolState.rotate + 90;
        this.changeToolState(newState, TOOL_IDS.rotate);
      }
    }
  };

  private changeToolState = (
    tool: ToolState,
    toolId: string,
    isAnonymousAction: boolean = false
  ) => {
    const newToolState: ToolState = { ...this.previousToolState, ...tool };
    const toolStateCopy = JSON.parse(JSON.stringify(newToolState)) as ToolState;

    try {
      if (PHOTO_FILTERS.indexOf(toolId) === -1) {
        this.applyTilt(newToolState);
        this.applyCrop(newToolState);
        this.applyRotate(newToolState);
      }
    } catch (error) {
      return;
    }

    this.setState({ toolState: { ...newToolState } });
    this.previousToolState = newToolState;
    this.previousTool = toolId !== TOOL_IDS.rotate ? toolId : this.previousTool;

    if (!isAnonymousAction) {
      if (toolId === this.previousTool && toolId) {
        clearTimeout(this.timerIndex);
      }
      this.timerIndex = setTimeout(
        this.addHistoryRecord,
        300,
        toolStateCopy,
        toolId
      );
    }
  };

  private addHistoryRecord = (toolState: ToolState, toolId: string) => {
    if (this.cropper) {
      const record = new HistoryRecord();
      record.cropperState = this.cropper.getData();
      record.cropperBoxState = this.cropper.getCropBoxData();
      record.toolState = toolState;
      record.toolId = toolId;
      record.horizontalPivot = this.horizontalPivot;
      record.verticalPivot = this.verticalPivot;

      if (toolState.crop.isApplyCrop) {
        record.image = this.state.currentPhoto;
      }

      this.history.addRecord(record);
      this.setState({ undoDisabled: this.history.isEmpty });
    }
  };

  private applyTilt = (newToolState: ToolState) => {
    if (this.previousToolState && this.cropper) {
      const angleDelta = newToolState.tilt - this.previousToolState.tilt;
      if (angleDelta !== 0) {
        this.cropper.rotate(angleDelta);
      }
    }
  };

  private applyRotate = (newToolState: ToolState) => {
    if (this.previousToolState && this.cropper) {
      const angleDelta = newToolState.rotate - this.previousToolState.rotate;
      if (angleDelta !== 0) {
        this.cropper.clear();
        this.cropper.setDragMode("none");
        this.cropper.rotate(angleDelta);

        if ((newToolState.rotate / 90) % 2 === 1) {
          this.cropper.zoomTo(
            this.verticalPivot + this.verticalPivot * newToolState.crop.zoom
          );
        } else {
          this.cropper.zoomTo(
            this.horizontalPivot + this.horizontalPivot * newToolState.crop.zoom
          );
        }

        if (this.selectedMode === TOOL_IDS.crop) {
          this.cropper.crop();
          this.cropper.setDragMode("move");
        }
        this.cropper.setCropBoxData(this.cropper.getContainerData());
      }
    }
  };

  private applyCrop = (newToolState: ToolState) => {
    if (this.cropper) {
      const cropBoxData = this.cropper.getCropBoxData();
      this.cropper.setCropBoxData({
        ...this.cropper.getCropBoxData(),
        height: 0,
        width: 0,
      });
      this.cropper.clear();
      this.cropper.setDragMode("none");

      this.runZooming = true;
      if (!newToolState.crop.isApplyCrop) {
        if ((newToolState.rotate / 90) % 2 === 1) {
          this.cropper.zoomTo(
            this.verticalPivot + this.verticalPivot * newToolState.crop.zoom
          );
        } else {
          this.cropper.zoomTo(
            this.horizontalPivot + this.horizontalPivot * newToolState.crop.zoom
          );
        }
      }

      this.cropper.crop();
      this.cropper.setDragMode("move");
      this.runZooming = false;
      this.cropper.setCropBoxData(cropBoxData);

      if (newToolState.crop.isApplyCrop) {
        const photo = this.cropImage();

        if (!photo) {
          return;
        }

        newToolState.crop.isApplyCrop = false;
        newToolState.crop.zoom = 0;
        this.applyFullSizeCropperBox = true;
        this.setState({ currentPhoto: { ...photo } });
      }
    }
  };

  private configWorkspace = (toolId?: string) => {
    if (!(this.props.photo && this.cropper)) {
      return;
    }

    toolId = toolId ? toolId : this.state.selectedToolId;

    if (this.selectedMode === toolId) {
      return;
    }

    if (PHOTO_FILTERS.indexOf(toolId) > -1) {
      this.cropper.clear();
      this.cropper.setDragMode("none");
      this.setState({ isValidCrop: true });
      this.selectedMode = toolId;
    } else if (toolId === TOOL_IDS.tilt) {
      this.cropper.clear();
      this.setState({ isValidCrop: true });
      this.cropper.setDragMode("move");
      this.selectedMode = TOOL_IDS.tilt;
    } else if (toolId === TOOL_IDS.crop) {
      this.cropper.crop();
      this.cropper.setDragMode("move");

      if (this.history && !this.history.isEmpty) {
        const record = this.history.getLastKnownRecord(
          (historicalRecord) => !!historicalRecord.cropperBoxState.height
        );
        this.cropper.setCropBoxData(record.cropperBoxState);
      } else {
        this.setFullSizeCropperBox();
      }
      this.selectedMode = TOOL_IDS.crop;
    } else {
      this.cropper.clear();
      this.cropper.setDragMode("none");
    }
  };

  private setFilter = () => {
    const innerCropper = this.cropper && (this.cropper as any).cropper;

    // It is important to set up both image and viewBoxImage styles cause one of them used during crop, and another one on preview.
    if (innerCropper && innerCropper.image && innerCropper.image.style) {
      innerCropper.image.style.filter = `contrast(${this.state.toolState.contrast}%) brightness(${this.state.toolState.brightness}%)`;
    }

    if (
      innerCropper &&
      innerCropper.viewBoxImage &&
      innerCropper.viewBoxImage.style
    ) {
      innerCropper.viewBoxImage.style.filter = `contrast(${this.state.toolState.contrast}%) brightness(${this.state.toolState.brightness}%)`;
    }
  };

  private cropImage = (): IPhotoModel | undefined => {
    const photo = { url: "" };

    if (this.props.preventCrop) {
      return;
    }

    if (this.cropper) {
      const canvas = this.cropper.getCroppedCanvas({
        fillColor: "#f8f8f8",
        imageSmoothingQuality: "high",
        maxHeight: MAX_CROPPER_DIMENSIONS,
        maxWidth: MAX_CROPPER_DIMENSIONS,
      });
      const context = canvas.getContext("2d");

      if (context) {
        const imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );

        if (this.props.validateApply && !this.props.validateApply(canvas)) {
          throw Error("Image check failed");
        }

        context.putImageData(imageData, 0, 0);

        const newUrl = canvas.toDataURL(this.props.imageType);
        photo.url = this.insertImageMetadata(newUrl);
      }
    }

    return photo;
  };

  private insertImageMetadata = (url: string) => {
    let resultUrl = url;

    try {
      resultUrl = this.imageMetadataManager.insertMetadataToImageDataUrl(url);
    } catch (error) {
      this.props.logError && this.props.logError(error);
    }

    return resultUrl;
  };

  private prepareImage = (): IPhotoModel => {
    const photo = { url: "" };

    if (this.cropper) {
      const canvas = this.cropper.getCroppedCanvas({ fillColor: "#f8f8f8" });
      if (canvas) {
        const context = canvas.getContext("2d");
        if (context) {
          const imageData = context.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );

          const canvasPixelArray = imageData.data;
          const canvasPixelArrayLength = canvasPixelArray.length;

          const contrastCoefficient = this.state.toolState.contrast / 100;

          for (let i = 0; i < canvasPixelArrayLength; i += 4) {
            canvasPixelArray[i] =
              ((canvasPixelArray[i] / 255 - 0.5) * contrastCoefficient + 0.5) *
              255;
            canvasPixelArray[i + 1] =
              ((canvasPixelArray[i + 1] / 255 - 0.5) * contrastCoefficient +
                0.5) *
              255;
            canvasPixelArray[i + 2] =
              ((canvasPixelArray[i + 2] / 255 - 0.5) * contrastCoefficient +
                0.5) *
              255;
          }

          const brightnessCoefficient = this.state.toolState.brightness / 100;

          for (let i = 0; i < canvasPixelArrayLength; i += 4) {
            canvasPixelArray[i] -=
              canvasPixelArray[i] * (1 - brightnessCoefficient);
            canvasPixelArray[i + 1] -=
              canvasPixelArray[i + 1] * (1 - brightnessCoefficient);
            canvasPixelArray[i + 2] -=
              canvasPixelArray[i + 2] * (1 - brightnessCoefficient);
          }

          context.putImageData(imageData, 0, 0);
          const newUrl = canvas.toDataURL(this.props.imageType);
          photo.url = this.insertImageMetadata(newUrl);
        }
      }
    }

    return photo;
  };

  private initHistory = () => {
    if (this.cropper) {
      const cropperContainer = this.cropper.getContainerData();
      const cropBox = this.cropper.getCropBoxData();

      const history = new HistoryRecord();
      history.toolState = INITIAL_TOOL_STATE;
      history.cropperBoxState = { ...cropBox, ...cropperContainer };
      history.horizontalPivot = this.getHorizontalPivot();
      history.verticalPivot = this.getVerticalPivot();
      history.image = this.state.currentPhoto;
      history.toolId = TOOL_IDS.crop;
      history.cropperState = this.cropper.getData();

      this.history = new History(history);
    }
  };

  private getHorizontalPivot = (): number => {
    let horizontalPivot = 0;

    if (this.cropper) {
      const canvasData = this.cropper.getCanvasData();
      horizontalPivot = canvasData.width / canvasData.naturalWidth;
    }

    return horizontalPivot;
  };

  private getVerticalPivot = (): number => {
    let verticalPivot = 0;

    if (this.cropper) {
      const imageData = this.cropper.getImageData();
      const canvasData = this.cropper.getCanvasData();

      if (imageData.width > imageData.height) {
        verticalPivot = canvasData.height / canvasData.naturalWidth;
      } else {
        verticalPivot = canvasData.height / canvasData.naturalHeight;
      }
    }

    return verticalPivot;
  };

  private setFullSizeCropperBox = () => {
    if (this.cropper) {
      const cropperContainer = this.cropper.getContainerData();
      const cropBox = this.cropper.getCropBoxData();
      this.cropper.setCropBoxData({ ...cropBox, ...cropperContainer });
    }
  };

  private getTools = (): Tool[] => {
    return [
      {
        name: this.props.texts.photoEditor.toolNames.crop,
        id: TOOL_IDS.crop,
        icon: "icon-crop",
        controlType: "tab",
      },
      {
        name: this.props.texts.photoEditor.toolNames.brightness,
        id: TOOL_IDS.brightness,
        icon: "icon-brightness",
        controlType: "tab",
      },
      {
        id: TOOL_IDS.contrast,
        name: this.props.texts.photoEditor.toolNames.contrast,
        icon: "icon-contrast",
        controlType: "tab",
      },
      {
        name: this.props.texts.photoEditor.toolNames.rotate,
        id: TOOL_IDS.rotate,
        icon: "icon-rotate",
        controlType: "button",
      },
    ];
  };
}
