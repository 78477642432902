import React, { FC, useState } from "react";
import { Form, Formik } from "formik";
import { AlertMessage } from "../../Molecules/AlertMessage/AlertMessage";
import { Spinner } from "../../Atoms/Spinner/Spinner";
import Portal from "../../Atoms/Portal";
import { Popup } from "../../Molecules/Popup/Popup";
import { TextInputFormik } from "../../Atoms/FormikInputs/TextInputFormik/TextInputFormik";
import RichTextAreaFormik from "../../Atoms/FormikInputs/RichTextAreaFormik";
import { CheckBoxFormik } from "../../Atoms/FormikInputs/CheckboxFormik/CheckboxFormik";
import {
  MAX_MESSAGE_LENGTH,
  validationSchema as defaultValidationSchema,
} from "./validationSchema";
import { IDropzoneUploaderFile } from "../../Molecules/DropzoneUploader/DropzoneUploader";
import { DropzoneFormik } from "../../Atoms/FormikInputs/DropzoneFormik/DropzoneFormik";
import { config } from "../../../config";
import {
  NotificationToast,
  NotificationToastTypes,
} from "../../Atoms/NotificationToast/NotificationToast";
import { TFunction, useTranslation } from "../Translations/TranslationContext";
import { ObjectSchema } from "yup";
import { DOMPurify as DOMPurifyWithTargetBlankHook } from "../../Molecules/RichTextArea/utils";
import "./PerformerContact.scss";

export interface IPerformerContactProps {
  closeForm: () => void;
  performerName: string;
  senderName: string;
  senderEmail: string;
  performerId: string;
  isOpen?: boolean;
  canAttachFiles?: boolean;
  submitformEndpoint?: string;
  emailSubjectLine?: string;
  customValidationSchema?: (t?: TFunction) => ObjectSchema<any>;
  successCallback?: () => void;
}

export interface IPerformerContactForm {
  message: string;
  subject: string;
  senderName: string;
  senderEmail: string;
  senderTelephoneNumber: string;
  companyName: string;
  companyWebsite: string;
  consent: boolean;
  attachments: IDropzoneUploaderFile[];
}

interface NotificationToastState {
  isOpen: boolean;
  type: NotificationToastTypes;
}

const PerformerContact: FC<IPerformerContactProps> = ({
  performerName,
  senderEmail,
  senderName,
  closeForm,
  performerId,
  isOpen,
  canAttachFiles = true,
  submitformEndpoint = "selfrepresented",
  emailSubjectLine,
  customValidationSchema,
  successCallback,
}) => {
  const t = useTranslation();
  const [showLoader, setShowLoader] = useState(false);
  const [notificationToast, setNotificationToast] =
    useState<NotificationToastState>({
      isOpen: false,
      type: NotificationToastTypes.Success,
    });

  const validationSchema = customValidationSchema ?? defaultValidationSchema;

  const submitContactPerformer = async (values: IPerformerContactForm) => {
    setShowLoader(true);
    try {
      await fetch(
        `${config.profileApiUrl}/profiles/${performerId}/${submitformEndpoint}`,
        {
          method: "POST",
          body: JSON.stringify({
            ...values,
            message: DOMPurifyWithTargetBlankHook.sanitize(values.message),
            attachments: values.attachments.map(({ file: { name } }) => name),
          }),
        }
      );

      setNotificationToast({
        isOpen: true,
        type: NotificationToastTypes.Success,
      });

      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      setNotificationToast({
        isOpen: true,
        type: NotificationToastTypes.Error,
      });
    }
    closeForm();
    setShowLoader(false);
  };

  const uploadFiles = async (files: IDropzoneUploaderFile[]): Promise<void> => {
    const result: { url: string }[] = await (
      await fetch(
        `${config.profileApiUrl}/profiles/${performerId}/selfrepresented/attachments`,
        {
          method: "POST",
          body: JSON.stringify(files.map((file) => file.file.name)),
        }
      )
    ).json();

    await Promise.all(
      result.map(async ({ url }, id) => {
        return await fetch(url, {
          method: "PUT",
          body: files[id].file,
          headers: {
            skipAuth: "true",
          },
        });
      })
    );
  };

  const subject = emailSubjectLine ?? t("common.contactPerformerModal.subject");

  const initialValues = {
    message: "",
    subject,
    senderName,
    senderEmail: senderEmail || "",
    senderTelephoneNumber: "",
    companyWebsite: "",
    companyName: "",
    consent: false,
    attachments: [],
  };

  const notificationText =
    notificationToast.type === "error"
      ? t("common.contactPerformerModal.failed")
      : t("common.contactPerformerModal.success");

  return (
    <Portal>
      <>
        {isOpen && (
          <Popup
            width={{ lg: 6, md: 8 }}
            priority="medium"
            close={closeForm}
            texts={{ closePopup: "" }}
          >
            <Formik<IPerformerContactForm>
              initialValues={initialValues}
              validationSchema={validationSchema(t)}
              onSubmit={submitContactPerformer}
            >
              {({ submitForm }) => {
                return (
                  <div className="c-performer-contact">
                    <AlertMessage
                      texts={{
                        title: `${t(
                          "common.contactPerformerModal.formHeader"
                        )} ${performerName}`,
                      }}
                      buttons={[
                        {
                          name: t("common.buttons.sendMessage"),
                          type: "primary",
                          click: submitForm,
                        },
                        {
                          name: t("common.buttons.cancel"),
                          type: "secondary",
                          click: closeForm,
                        },
                      ]}
                    >
                      <Form className="c-performer-contact-form">
                        <div className="c-performer-contact-form__additional-text">
                          {t("common.contactPerformerModal.additionalText")}
                        </div>
                        <TextInputFormik
                          name="senderName"
                          label={t("common.labels.yourName")}
                        />
                        <TextInputFormik
                          name="senderEmail"
                          label={t("common.labels.yourEmail")}
                        />
                        <TextInputFormik
                          name="senderTelephoneNumber"
                          label={t("common.labels.yourContactNumberOptional")}
                        />
                        <TextInputFormik
                          name="companyName"
                          label={t("common.labels.companyName")}
                        />
                        <TextInputFormik
                          name="companyWebsite"
                          label={t("common.labels.companyWebsiteSocialMedia")}
                        />
                        <RichTextAreaFormik
                          name="message"
                          label={t("common.labels.yourMessage")}
                          maxLength={MAX_MESSAGE_LENGTH}
                        />
                        {canAttachFiles ? (
                          <DropzoneFormik
                            name="attachments"
                            label={t("common.labels.youMayAttach5Files")}
                            placeholder={t(
                              "common.placeholders.clickToSelectFileOrDropHere"
                            )}
                            errorModalTexts={{
                              title: t("common.validation.fileError"),
                              close: t("common.buttons.close"),
                              tooManyFilesErrorMessage: t(
                                "common.validation.tooManyFilesError"
                              ),
                              uploadErrorMessage: t(
                                "common.validation.uploadError"
                              ),
                            }}
                            multiple
                            acceptedFileTypes={[
                              ".png",
                              ".jpg",
                              ".jpeg",
                              ".gif",
                              ".pdf",
                              ".doc",
                              ".docx",
                            ]}
                            onDrop={uploadFiles}
                            maxFiles={5}
                          />
                        ) : null}
                        <CheckBoxFormik
                          name="consent"
                          value={true}
                          label={t(
                            "common.contactPerformerModal.consentTextLabel"
                          )}
                        />
                      </Form>
                    </AlertMessage>
                    {showLoader && (
                      <div className="c-performer-contact__spinner">
                        <Spinner />
                      </div>
                    )}
                  </div>
                );
              }}
            </Formik>
          </Popup>
        )}
        <NotificationToast
          text={notificationText}
          type={notificationToast.type}
          stickToComponent={false}
          show={notificationToast.isOpen}
          handleHide={() =>
            setNotificationToast((state) => ({ ...state, isOpen: false }))
          }
          timeoutDuration={6000}
        />
      </>
    </Portal>
  );
};

export default PerformerContact;
