import React from "react";
import {
  Popup,
  IAlertMessageProps,
  AlertMessage,
  Checkbox,
  getCookie,
  setCookie,
} from "component-library";

import { GlobalAlertMessage } from "shared/modules/Common/GlobalAlert";
import {
  IEditCreditsFormProps,
  EditCreditsForm,
} from "flows/UploadingPhotoFlow/EditCreditsForm";
import {
  IPopupFlowBaseComponentState,
  PopupFlowBaseComponent,
} from "flows/PopupFlowBaseComponent";
import {
  PhotoEditor,
  IPhotoEditorContent,
  IPhotoEditorProps,
} from "components/flows/Common/PhotoEditor";

import PhotoService from "services/PhotoService";

import { IFlowContextProps, withFlowContext } from "contexts/FlowContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";
import { PhotoToolState } from "shared/modules/Common";

import {
  IUploadingPhotoFlowContent,
  getError,
  getSaveDialogSettings,
  getCancelDialogSettings,
  getErrorDialogSettings,
} from "./UploadingPhotoFlowContent";
import { ICancelablePromise, makeCancelable } from "shared/utils/promise";
import { Photo, PhotoEdit } from "models";
import {
  ILogContextProps,
  withLog,
  LogLevel,
} from "shared/contexts/LoggerContext";
import { LOG_DOMAIN, LOG_ACTION_DICTIONARY } from "app/store/logger-dictionary";
import { FlowStep } from "flows/PopupFlowBaseComponent/FlowStep";
import { InvalidOperationError } from "shared/utils/exceptions";
import compose from "shared/utils/compose";
import { ExpiryTimeUnits } from "component-library/src/generics/cookies";

const SKIP_SAVE_ALERT_COOKIE_NAME = "skipSaveAlert";
const SKIP_SAVE_ALERT_COOKIE_EXPIRY_DAYS = 30;

const STEP_NAME = {
  editPhoto: "edit-photo",
  editCredits: "edit-credits",
  error: "error",
  confirmSave: "confirm-save",
  cancel: "cancel",
};

const POPUP_SIZES = {
  medium: { lg: 4, md: 6 },
  large: { lg: 8, md: 10 },
};

export interface IUploadingPhotoFlowProps
  extends IFlowContextProps,
    IGlobalContextProps,
    ILogContextProps {
  photo: Photo;
  content: IUploadingPhotoFlowContent;
}

interface IUploadingPhotoFlowState extends IPopupFlowBaseComponentState {
  imageType?: string;
  photo?: Photo;
  skipSaveAlert?: boolean;
  showContent: boolean;
}

export class UploadingPhotoFlow extends PopupFlowBaseComponent<
  IUploadingPhotoFlowProps,
  IUploadingPhotoFlowState
> {
  public readonly state: Readonly<IUploadingPhotoFlowState> = {
    currentStep: STEP_NAME.editPhoto,
    showContent: true,
  };

  private photoEditorState: PhotoToolState;
  private photoEditorRef: React.RefObject<PhotoEditor> =
    React.createRef<PhotoEditor>();

  private photoPromise: ICancelablePromise;

  private errorMessage = "";

  private isAvailableClose = false;
  private isProcessStarted = false;

  constructor(props: Readonly<IUploadingPhotoFlowProps>) {
    super(props);

    this.flowSteps = this.getFlowSteps();
  }

  public render() {
    const component = this.getCurrentStep();
    const popupConfiguration = this.getCurrentPopupConfiguration();
    const { showContent } = this.state;

    let view: React.ReactNode = null;

    if (popupConfiguration && component && showContent) {
      view = <Popup {...popupConfiguration}>{component}</Popup>;
    }

    return view;
  }

  public componentWillUnmount() {
    const { logger } = this.props;
    logger.closeAllGroups();

    logger.appendInfo(
      "Force push log, close promises",
      undefined,
      undefined,
      true
    );

    this.photoPromise && this.photoPromise.cancel();
    //@ts-ignore
    delete this.photoEditorState;
  }

  public async componentDidMount() {
    const { logger } = this.props;
    logger.openGroup("photo preload");

    this.photoPromise = makeCancelable(this.uploadPhoto());

    try {
      const [photo, imageType] = await this.photoPromise.promise;

      this.setState({ photo, imageType });
    } catch (error) {
      if (error.isCanceled) {
        logger.appendWarning("Warning: The image request was canceled", {
          error,
        });
      } else {
        logger.appendError("Error: Converting by url image to base64", error);
      }
    }

    logger.closeGroup();
  }

  private uploadPhoto = async (): Promise<[Photo, string]> => {
    const { logger, photo } = this.props;

    const blob = await this.getPhotoBlob(photo);

    logger.appendInfo("Started: Parsing blob to URL format", {
      size: blob.size,
      type: blob.type,
    });

    const url = URL.createObjectURL(blob);

    logger.appendInfo("Executed: Photo parsing");

    return [{ ...photo, url }, blob.type];
  };

  private getPhotoBlob = async (photo: Photo) => {
    const { logger } = this.props;

    logger.appendInfo("Started: Converting a local image to base64", { photo });

    const reader = await fetch(photo.url);
    logger.appendInfo("Log: Retrieved access to photo", { reader });

    if (!reader.ok) {
      throw new InvalidOperationError("Photo cant be retrieved by url", {
        reader,
      });
    }

    logger.appendInfo("Info: Retrieving blob of photo", { reader });
    const blob = await reader.blob();

    logger.appendInfo("Executed: Converting a local image to base64", {
      size: blob.size,
      type: blob.type,
    });

    return blob;
  };

  private getFlowSteps = (): FlowStep[] => {
    const {
      content: { photoEditFlow: photoEditFlowContent, ...content },
      logger,
    } = this.props;
    logger.appendInfo("Log: Creating flow steps");

    const {
      closePopupAction,
      photoToolActions,
      cancelPhotoActions,
      confirmPhotoActions,
      photoCreditsFormActions,
      photoErrorActions,
    } = LOG_ACTION_DICTIONARY.uploadingPhoto;

    const photoEditorTexts: IPhotoEditorContent =
      photoEditFlowContent.editPhoto;

    const closePopup = logger.wrapActionFunction(
      this.closeFlow,
      closePopupAction
    );

    return [
      {
        name: STEP_NAME.editPhoto,
        component: this.renderPhotoEditor,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.large,
          { closePopup: photoEditFlowContent.closePopup },
          closePopup,
          "t-setting__full-width-sm"
        ),
        settings: {
          savePhoto: logger.wrapActionFunction(
            this.preSavePhoto,
            photoToolActions.save
          ),
          cancelEditing: logger.wrapActionFunction(
            this.cancelPhotoEditing,
            photoToolActions.cancel
          ),
          content: photoEditorTexts,
          errorNotification: this.showPopup,
          showHelpPopup: this.showPopup,
        },
      },
      {
        name: STEP_NAME.editCredits,
        component: this.renderPhotoCreditsEditor,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.large,
          content.closePopup,
          closePopup,
          "t-setting__full-width-sm"
        ),
        settings: {
          content: this.props.content.editCreditsForm,
          getPhotographer: this.getPhotographer,
          save: logger.wrapActionFunction(
            this.savePhoto,
            photoCreditsFormActions.save
          ),
          cancel: logger.wrapActionFunction(
            this.forceClosePopup,
            photoCreditsFormActions.cancel
          ),
          showHelpPopup: this.showPopup,
        },
      },
      {
        name: STEP_NAME.confirmSave,
        component: this.renderSaveDialog,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          { closePopup: photoEditFlowContent.saveDialog.closeSavePopup },
          closePopup
        ),
        settings: getSaveDialogSettings({
          content: photoEditFlowContent,
          confirmSave: logger.wrapActionFunction(
            this.confirmSavePhoto,
            confirmPhotoActions.continue
          ),
          photoEdit: logger.wrapActionFunction(
            this.backToPhotoEditing,
            confirmPhotoActions.backToEdit
          ),
          close: logger.wrapActionFunction(
            this.forceClosePopup,
            confirmPhotoActions.discard
          ),
        }),
      },
      {
        name: STEP_NAME.cancel,
        component: this.renderCancelDialog,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          { closePopup: photoEditFlowContent.cancelDialog.closeCancalPopup },
          closePopup
        ),
        settings: getCancelDialogSettings({
          content: photoEditFlowContent,
          close: logger.wrapActionFunction(
            this.forceClosePopup,
            cancelPhotoActions.discard
          ),
          photoEdit: logger.wrapActionFunction(
            this.backToPhotoEditing,
            cancelPhotoActions.backToEdit
          ),
          confirmSave: logger.wrapActionFunction(
            this.confirmSavePhoto,
            cancelPhotoActions.save
          ),
        }),
      },
      {
        name: STEP_NAME.error,
        component: this.renderErrorDialog,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          { closePopup: photoEditFlowContent.errorDialog.closeErrorPopup },
          closePopup
        ),
        settings: getErrorDialogSettings({
          content: photoEditFlowContent,
          photoEdit: logger.wrapActionFunction(
            this.backToPhotoEditing,
            photoErrorActions.backToEdit
          ),
        }),
      },
    ];
  };

  private renderPhotoEditor = (photoEditProps: IPhotoEditorProps) => {
    const { photo, imageType } = this.state;
    let component: React.ReactNode | null = null;

    if (photo && imageType) {
      component = (
        <PhotoEditor
          ref={this.photoEditorRef}
          {...photoEditProps}
          photo={photo}
          imageType={imageType}
          photoEditorData={this.photoEditorState}
          logError={this.logPhotoEditorError}
        />
      );
    }

    return component;
  };

  private renderPhotoCreditsEditor = (
    photoEditProps: IEditCreditsFormProps
  ) => {
    const photo: PhotoEdit = {
      ...(this.photoEditorState.builtImage as Photo),
      isMainPhoto: false,
    };

    return <EditCreditsForm {...photoEditProps} photo={photo} />;
  };

  private renderErrorDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage
      {...alertProps}
      {...{
        texts: {
          ...alertProps.texts,
          description: this.errorMessage,
        },
      }}
    />
  );

  private renderCancelDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage {...alertProps} />
  );

  public renderSaveDialog = (alertProps: IAlertMessageProps) => {
    const {
      content: { photoEditFlow },
    } = this.props;

    return (
      <AlertMessage {...alertProps}>
        <Checkbox
          htmlId="uploadingPhotoFlowDontShowAgain"
          name="dontShowAgain"
          value="dont"
          valueChanged={this.setSkipSaveAlertState}
          label={photoEditFlow.saveDialog.dontShowAgain}
          ariaLabel={photoEditFlow.saveDialog.dontShowAgain}
        />
      </AlertMessage>
    );
  };

  private logPhotoEditorError = (error: Error) => {
    this.props.logger.appendError("Photo editor error", error);
  };

  private closeFlow = () => {
    const { logger } = this.props;

    if (!this.isAvailableClose) {
      logger.appendInfo("Started: Closing uploading flow");

      this.isAvailableClose = true;
      const photoEditorContainer = this.photoEditorRef.current;

      let photoEditorState: PhotoToolState | undefined;

      try {
        photoEditorState =
          (photoEditorContainer &&
            photoEditorContainer.getPhotoEditorState()) ||
          undefined;
      } catch (error) {
        logger.appendError(
          "Error: Getting photo tool state has been failed.",
          error
        );
      }

      if (photoEditorState) {
        const { history } = photoEditorState;

        const changeHistory = history
          .getHistoryCopy()
          .map(({ toolState, toolId }) => ({ toolState, toolId }));

        logger.appendInfo("Log: Registering Photo Tool state.", {
          changeHistory,
        });

        if (!history.isEmpty) {
          this.photoEditorState = photoEditorState;
          logger.appendInfo("Called: Moving to cancel step");
          this.moveToStep(STEP_NAME.cancel);
          return;
        }
      }
    }

    logger.appendInfo("Called: Closing flow context");
    this.props.flowContext.changeContext("", null);
  };

  private backToPhotoEditing = () => {
    const { logger } = this.props;

    this.isAvailableClose = false;

    logger.appendInfo("Called: Moving edit photo step");
    this.moveToStep(STEP_NAME.editPhoto);
  };

  private cancelPhotoEditing = (data: PhotoToolState) => {
    const { logger } = this.props;

    this.photoEditorState = data;

    logger.appendInfo("Called: Closing flow", {
      history: data.history
        .getHistoryCopy()
        .map(({ toolState, toolId }) => ({ toolState, toolId })),
      toolState: { ...data.toolState },
    });
    this.closeFlow();
  };

  public preSavePhoto = (data: PhotoToolState) => {
    const { logger } = this.props;

    logger.appendInfo("Log: Saving photo state");

    this.photoEditorState = data;
    const showAlert = getCookie(SKIP_SAVE_ALERT_COOKIE_NAME);

    if (showAlert) {
      logger.appendInfo("Called: Confirming of photo saving", { showAlert });
      this.confirmSavePhoto();
    } else {
      this.isAvailableClose = true;
      logger.appendInfo("Log: Moving to photo saving confirmation step", {
        showAlert,
      });

      this.moveToStep(STEP_NAME.confirmSave);
    }
  };

  private confirmSavePhoto = () => {
    const { logger } = this.props;
    const { skipSaveAlert } = this.state;

    logger.appendInfo("Log: Updating skipSaveAlert parameter", {
      skipSaveAlert,
    });

    skipSaveAlert &&
      setCookie(SKIP_SAVE_ALERT_COOKIE_NAME, "true", {
        expiryTime: SKIP_SAVE_ALERT_COOKIE_EXPIRY_DAYS,
        expiryTimeUnit: ExpiryTimeUnits.DAY,
      });

    logger.appendInfo("Log: Moving to edit photo credits");

    this.moveToPhotoCredits();
  };

  private savePhoto = async (photo: PhotoEdit) => {
    const { globalContext, content, logger } = this.props;

    if (!this.isProcessStarted) {
      this.setState({ showContent: false });
      this.isProcessStarted = true;

      logger.openGroup("photo uploading");

      globalContext.notifyListener(
        GlobalEventTypes.makeVisibleGlobalSpinner,
        true
      );

      try {
        const { isMainPhoto, photographer, description } = photo;
        logger.appendInfo("Started: Photo uploading process", {
          isMainPhoto,
          photographer,
          description,
        });

        const uploadedPhoto = await PhotoService.uploadPhoto(photo);

        if (photo.isMainPhoto) {
          logger.appendInfo("Called: Making main photo");
          await PhotoService.makeMainPhoto(uploadedPhoto);
        }

        logger.appendInfo("Called: Updating photo list");

        this.updatePhotoLists(photo);
      } catch (error) {
        this.isProcessStarted = false;
        this.isAvailableClose = true;

        logger.appendError("Error: Failed photo saving", error, {
          isMainPhoto: photo.isMainPhoto,
          photographer: photo.photographer,
          description: photo.description,
          base64Length: photo.url.length,
          urlPart: photo.url.substr(0, 100),
        });

        this.errorMessage = getError(
          error.errorStatuses,
          content.photoEditFlow
        );

        globalContext.notifyListener(GlobalEventTypes.closeAllGlobalAlert);

        logger.closeGroup(LogLevel.warning);
        logger.appendWarning("Log: Moving to error step", {});

        this.setState({ showContent: true });
        this.moveToStep(STEP_NAME.error);
      }
    }

    logger.closeGroup();
    logger.appendInfo("Log: Complete photo uploading, Finishing process");
    this.props.globalContext.notifyListener(
      GlobalEventTypes.makeVisibleGlobalSpinner,
      false
    );
  };

  private getPhotographer = async (name: string) => {
    const { logger } = this.props;

    try {
      return await PhotoService.getPhotographer(name);
    } catch (error) {
      logger.appendError("Error: Loading photographers", error, { name });
    }

    return Promise.resolve([]);
  };

  private updatePhotoLists = (photo: PhotoEdit) => {
    const { logger, globalContext } = this.props;
    logger.appendInfo("Called: Updating gallery photo");
    globalContext.notifyListener(GlobalEventTypes.updateGallery, {
      showDialog: true,
    });

    if (photo.isMainPhoto) {
      logger.appendInfo("Called: Updating main photo");
      globalContext.notifyListener(GlobalEventTypes.updateMainPhoto);
      globalContext.notifyListener(GlobalEventTypes.changeMainPhoto);
    } else {
      globalContext.notifyListener(GlobalEventTypes.uploadNewPhoto);
    }

    this.forceClosePopup();
  };

  private forceClosePopup = () => {
    const { logger } = this.props;

    logger.appendInfo("Called: Force closing popup");

    this.isAvailableClose = true;
    this.closeFlow();
  };

  private moveToPhotoCredits = () => {
    const { logger } = this.props;

    logger.appendInfo("Log: Moving to edit credits form");

    this.moveToStep(STEP_NAME.editCredits);
  };

  private showPopup = (message: GlobalAlertMessage) =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );

  private setSkipSaveAlertState = (checked: boolean) =>
    this.setState({ skipSaveAlert: checked });
}

export default compose(
  withGlobalContext,
  withFlowContext,
  withLog(LOG_DOMAIN.uploadingPhoto)
)(UploadingPhotoFlow);
