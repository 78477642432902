import React, { ComponentType } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  FEATURE_FLAGS,
  LaunchDarklyClientWithFlags,
  withLaunchDarklyConsumerHOC,
} from "shared-services";
import { authorizationService, UserAttributes } from "shared-auth";
import { UserRoleManager, UserRoles } from "core";
import {
  AccountType,
  AddOnEntitlements,
  getNavHeaderLinks,
  getPerformersSearchText,
  INavigationHeaderHandlers,
  myAccountLink,
  NavigationHeader,
  PremiumEntitlements,
} from "component-library";

import {
  IGlobalContextProps,
  withGlobalContext,
} from "shared/contexts/GlobalContext";

import { GlobalEventTypes } from "contexts/GlobalContext";
import { IRoxProps, withRoxAccess } from "contexts/RoxContext";

import PerformersService from "services/PerformersService";
import { getSearchAndFiltersFrontendUrl } from "services/UrlBuilder";

import { getAccountType, LOGO_LINK } from "./HeaderSectionContent";

import "./HeaderSection.scss";
import {
  IPerformerSearchProps,
  IPerformerSearchResult,
} from "component-library/src/components/Organisms/NavigationHeader/PerformerSearch/PerformerSearch";

interface IHeaderSectionReceivedProps {
  menuContainerRef: React.RefObject<Element>;
}
export interface IHeaderSectionProps
  extends IRoxProps,
    IGlobalContextProps,
    RouteComponentProps,
    IHeaderSectionReceivedProps,
    LaunchDarklyClientWithFlags {}

export class HeaderSection extends React.Component<
  IHeaderSectionProps & LaunchDarklyClientWithFlags
> {
  private readonly navigationHeaderHandlers: INavigationHeaderHandlers;
  private popupRef = React.createRef<HTMLDivElement>();
  private accountType: AccountType;

  constructor(props: IHeaderSectionProps) {
    super(props);

    this.accountType = getAccountType();

    this.navigationHeaderHandlers = {
      onSignInHandler: this.signIn,
      onSignOutHandler: this.signOut,
      onClickAccountHandler: this.accountClickHandler,
      onLocalizationChangedHandler: this.localizationChanged,
    };
  }

  public render() {
    const isLocalizationVisible =
      this.props.roxContainer.isEnabled("localization");

    const isSignedIn =
      !UserRoleManager.isInRole(UserRoles.anonymous) &&
      this.accountType !== AccountType.DefaultUser;

    const userName = authorizationService.getUserAttribute(
      UserAttributes.FullName
    );
    const performerSearchProps = this.getPerformerSearchProps();

    const memberGroup = authorizationService.getUserGroup();
    // replace with checkIsCastingBBC when authorizationService for this app
    const isCastingBBC =
      authorizationService.getUserAttribute(UserAttributes.IdentityProvider) ===
      "ipcheck";
    const isPremiumLEGACY = authorizationService.doesUserHaveEntitlements(
      PremiumEntitlements.Premium_Site_Access
    );
    const isAddonPerformer =
      authorizationService.doesUserHaveEntitlements(
        AddOnEntitlements.AddOnBundle
      ) || isPremiumLEGACY;
    const isAgencySettingsFlagEnabled =
      this.props.flags[FEATURE_FLAGS.AgencySettings];
    const isMediaRequestEnabled = this.props.flags[FEATURE_FLAGS.SelfTapes];

    const { lhNavBottomLinks, headerLabels, localPopUpText, mainNav } =
      getNavHeaderLinks(memberGroup, {
        isCastingBBC,
        isAddonPerformer,
        isAgencySettingsFlagEnabled,
        isMediaRequestEnabled,
      });

    return (
      <React.Fragment>
        <NavigationHeader
          menuContainer={this.props.menuContainerRef}
          popupContainer={this.popupRef}
          menuClassName="c-header-section__navigation-menu"
          accountType={this.accountType}
          userName={userName}
          userLinkUrl={myAccountLink}
          headerLabels={headerLabels}
          logoRedirectionLink={LOGO_LINK}
          links={[]}
          bottomNavLinks={lhNavBottomLinks}
          mainNavData={{
            handleNavigationItemClick: this.redirectToUrl,
            handleSubNavigationItemClick: this.redirectToUrl,
            ...mainNav,
          }}
          signedIn={isSignedIn}
          handlers={this.navigationHeaderHandlers}
          isLocalizationVisible={isLocalizationVisible}
          localizationTexts={localPopUpText}
          performerSearchProps={performerSearchProps}
        />
        <div ref={this.popupRef} />
      </React.Fragment>
    );
  }

  private localizationChanged = (language: string, location: string) =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.reloadLocalization
    );

  private accountClickHandler = () => window.location.assign(myAccountLink);

  private signIn = () => authorizationService.signInRedirect();

  private signOut = () => {
    authorizationService.signoutPortalRedirect();
  };

  private redirectToUrl =
    (event: React.MouseEvent<HTMLAnchorElement>, text: string, link: string) =>
    () =>
      window.location.assign(link);

  private loadSearchResults = async (
    searchTerm: string
  ): Promise<IPerformerSearchResult[]> =>
    await PerformersService.searchPerformers(searchTerm);

  private redirectToSearchSiteUrl = (href: string) => {
    const link = getSearchAndFiltersFrontendUrl(href);

    if (window.open(link, "_blank") === null) {
      window.location.replace(link);
    }
  };

  private showMoreResults = (searchTerm: string) =>
    this.redirectToSearchSiteUrl(`?performerName=${searchTerm}`);

  private getPerformerSearchProps = (): IPerformerSearchProps | undefined => {
    const { roxContainer } = this.props;

    const isCastingDirectorQuickSearchEnabled = roxContainer.isEnabled(
      "castingDirectorQuickSearch"
    );

    if (
      isCastingDirectorQuickSearchEnabled &&
      UserRoleManager.isInRole(UserRoles.castingDirector)
    ) {
      return {
        content: getPerformersSearchText(),
        loadSearchResults: this.loadSearchResults,
        showMoreResults: this.showMoreResults,
      };
    }

    return undefined;
  };
}

export default compose(
  withLaunchDarklyConsumerHOC(),
  withRoxAccess,
  withGlobalContext,
  withRouter
)(HeaderSection) as ComponentType<IHeaderSectionReceivedProps>;
