import React from "react";
import diff from "json-patch-gen";
import {
  Popup,
  IAlertMessageProps,
  AlertMessage,
  Checkbox,
  getCookie,
  setCookie,
} from "component-library";

import {
  IPhotoEditorContent,
  PhotoEditor,
  IPhotoEditorProps,
} from "components/flows/Common/PhotoEditor";
import {
  IPopupFlowBaseComponentState,
  PopupFlowBaseComponent,
} from "components/flows/PopupFlowBaseComponent";
import { GlobalAlertMessage } from "shared/modules/Common/GlobalAlert";

import PhotoService from "services/PhotoService";

import { IFlowContextProps, withFlowContext } from "contexts/FlowContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";
import { PhotoToolState } from "shared/modules/Common";

import { ICancelablePromise, makeCancelable } from "shared/utils/promise";
import { Photo, ImageLocation } from "models";
import {
  IPhotoEditingFlowContent,
  getSaveDialogSettings,
  getCancelDialogSettings,
  getErrorDialogSettings,
} from "./PhotoEditingFlowContent";
import { getImageUrl } from "services/UrlBuilder";
import { AdminPhotoEdit } from "flows/Common/AdminPhotoEdit";
import { UserRoleManager } from "core";
import { ILogContextProps, withLog } from "shared/contexts/LoggerContext";
import { LOG_DOMAIN, LOG_ACTION_DICTIONARY } from "app/store/logger-dictionary";
import { InvalidOperationError } from "shared/utils/exceptions";
import compose from "shared/utils/compose";
import { ExpiryTimeUnits } from "component-library/src/generics/cookies";

const STEP_NAME = {
  photoEditor: "photoEditor",
  cancel: "cancel",
  save: "save",
  error: "error",
};

const POPUP_SIZES = {
  medium: { lg: 4, md: 6 },
  large: { lg: 8, md: 10 },
  extraLarge: { lg: 12, md: 12 },
};

const SKIP_SAVE_ALERT_COOKIE_NAME = "skipSaveAlert";
const SKIP_SAVE_ALERT_COOKIE_EXPIRY_DAYS = 30;

export interface IPhotoEditingFlowProps
  extends IFlowContextProps,
    IGlobalContextProps,
    ILogContextProps {
  photo: Photo;
  content: IPhotoEditingFlowContent;
}

interface IPhotoEditingFlowState extends IPopupFlowBaseComponentState {
  photo?: Photo;
  skipSaveAlert: boolean;
  imageType: string;
  error?: string;
  showContent: boolean;
}

export class PhotoEditingFlow extends PopupFlowBaseComponent<
  IPhotoEditingFlowProps,
  IPhotoEditingFlowState
> {
  public readonly state: Readonly<IPhotoEditingFlowState> = {
    currentStep: STEP_NAME.photoEditor,
    skipSaveAlert: false,
    imageType: "",
    showContent: true,
  };

  private photoEditorState?: PhotoToolState;

  private photoEditorRef = React.createRef<PhotoEditor>();
  private adminPhotoEditorRef = React.createRef<AdminPhotoEdit>();

  private errorMessage: string;
  private isAvailableClose = false;
  private isProcessStarted = false;
  private updatedPhotoData?: Photo;
  private photoPromise: ICancelablePromise<[Photo, string]>;

  constructor(props: IPhotoEditingFlowProps) {
    super(props);

    this.makeFlowSteps();
  }

  public render() {
    const component = this.getCurrentStep();
    const popupConfiguration = this.getCurrentPopupConfiguration();
    const { showContent } = this.state;

    let view: React.ReactNode = null;

    if (popupConfiguration && component && showContent) {
      view = <Popup {...popupConfiguration}>{component}</Popup>;
    }

    return view;
  }

  public componentWillUnmount() {
    const { logger } = this.props;
    logger.closeAllGroups();

    logger.appendInfo(
      "Force push log, close promises",
      undefined,
      undefined,
      true
    );

    this.photoPromise && this.photoPromise.cancel();
    //@ts-ignore
    delete this.photoEditorState;
  }

  public async componentDidMount() {
    const { logger, content } = this.props;
    logger.openGroup("photo preload");

    this.photoPromise = makeCancelable(this.uploadPhoto());
    try {
      const [photo, imageType] = await this.photoPromise.promise;

      this.setState({ photo, imageType });
    } catch (error) {
      if (error.isCanceled) {
        logger.appendWarning("Warning: The image request was canceled", {
          error,
        });
      } else {
        logger.appendError("Error: Converting by url image to base64", error);
        this.setState({ error: content.photoLoadingError });
      }
    }

    logger.closeGroup();
  }

  private uploadPhoto = async (): Promise<[Photo, string]> => {
    const { logger, photo } = this.props;

    const blob = await this.getPhotoBlob(photo);
    logger.appendInfo("Started: Parsing blob to URL format", {
      size: blob.size,
      type: blob.type,
    });

    const url = URL.createObjectURL(blob);

    logger.appendInfo("Executed: Photo parsing");

    return [{ ...photo, url }, blob.type];
  };

  private getPhotoBlob = async (photo: Photo) => {
    const { logger } = this.props;

    logger.appendInfo("Started: Converting by url to base64", { photo });

    const photoUrl = getImageUrl(photo.url, ImageLocation.PhotoEditor);
    logger.appendInfo("Log: Updating photo link", { photoUrl });

    const reader = await fetch(photoUrl);
    logger.appendInfo("Log: Retrieved access to photo", { reader });

    if (!reader.ok) {
      throw new InvalidOperationError("Photo cant be retrieved by url", {
        reader,
      });
    }

    logger.appendInfo("Info: Retrieving blob of photo", { reader });
    const blob = await reader.blob();

    logger.appendInfo("Executed: Converting by url to base64", {
      size: blob.size,
      type: blob.type,
    });

    return blob;
  };

  private makeFlowSteps = () => {
    const { content, logger } = this.props;
    logger.appendInfo("Log: Creating flow steps");

    const {
      closePopupAction,
      photoEditorActions,
      cancelEditingActions,
      savePhotoActions,
      errorActions,
    } = LOG_ACTION_DICTIONARY.photoEdit;

    const photoEditorContent: IPhotoEditorContent =
      this.props.content.editPhoto;

    const closePopup = logger.wrapActionFunction(
      this.closeFlow,
      closePopupAction
    );

    const photoEditorSize = UserRoleManager.isInRole("Spotlight")
      ? POPUP_SIZES.extraLarge
      : POPUP_SIZES.large;
    this.flowSteps = [
      {
        name: STEP_NAME.photoEditor,
        component: this.renderPhotoTool,
        data: {},
        popupSettings: this.createPopupConfiguration(
          photoEditorSize,
          content.closePopup,
          closePopup,
          "t-setting__full-width-sm"
        ),
        settings: {
          content: photoEditorContent,
          errorNotification: this.showPopup,
          savePhoto: logger.wrapActionFunction(
            this.preSavePhoto,
            photoEditorActions.savePhoto
          ),
          cancelEditing: logger.wrapActionFunction(
            this.cancelPhotoEditing,
            photoEditorActions.cancelEditing
          ),
          showHelpPopup: logger.wrapActionFunction(
            this.showPopup,
            photoEditorActions.showHelp
          ),
        },
      },
      {
        name: STEP_NAME.cancel,
        component: this.renderCancelDialog,
        data: {},
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          content.closePopup,
          closePopup
        ),
        settings: getCancelDialogSettings({
          content: content.cancelDialog,
          close: logger.wrapActionFunction(
            this.forceClosePopup,
            cancelEditingActions.close
          ),
          editPhoto: logger.wrapActionFunction(
            this.backToPhotoEditing,
            cancelEditingActions.backToPhotoEditing
          ),
          save: logger.wrapActionFunction(
            this.savePhoto,
            cancelEditingActions.savePhoto
          ),
        }),
      },
      {
        name: STEP_NAME.save,
        component: this.renderSaveDialog,
        data: {},
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          content.closePopup,
          closePopup
        ),
        settings: getSaveDialogSettings({
          content: content.saveDialog,
          save: logger.wrapActionFunction(
            this.savePhoto,
            savePhotoActions.savePhoto
          ),
          editPhoto: logger.wrapActionFunction(
            this.backToPhotoEditing,
            savePhotoActions.backToPhotoEditing
          ),
          close: logger.wrapActionFunction(
            this.forceClosePopup,
            savePhotoActions.close
          ),
        }),
      },
      {
        name: STEP_NAME.error,
        component: this.renderErrorDialog,
        data: {},
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          content.closePopup,
          closePopup
        ),
        settings: getErrorDialogSettings({
          content: content.errorDialog,
          editPhoto: logger.wrapActionFunction(
            this.backToPhotoEditing,
            errorActions.backToPhotoEditing
          ),
        }),
      },
    ];
  };

  private renderPhotoTool = (photoEditProps: IPhotoEditorProps) => {
    if (UserRoleManager.isInRole("Spotlight")) {
      const { photographer = "", description = "" } =
        this.updatedPhotoData || this.state.photo || {};
      return this.renderAdminPhotoEdit(photoEditProps, {
        photographer,
        description,
      });
    } else {
      return this.renderPhotoEditor(photoEditProps);
    }
  };

  private renderPhotoEditor = (photoEditProps: IPhotoEditorProps) => {
    const { photo, imageType, error } = this.state;

    return (
      <PhotoEditor
        ref={this.photoEditorRef}
        {...photoEditProps}
        error={error}
        photo={photo}
        imageType={imageType}
        photoEditorData={this.photoEditorState}
        logError={this.logPhotoEditorError}
      />
    );
  };

  private renderAdminPhotoEdit = (
    photoEditProps: IPhotoEditorProps,
    photoMetadata: any
  ) => (
    <AdminPhotoEdit
      ref={this.adminPhotoEditorRef}
      {...photoEditProps}
      error={this.state.error}
      photo={{ ...this.state.photo, ...photoMetadata }}
      imageType={this.state.imageType}
      photoEditorData={this.photoEditorState}
      logError={this.logPhotoEditorError}
    />
  );

  private renderSaveDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage {...alertProps}>
      <Checkbox
        htmlId="photoEditingFlowDontShowAgain"
        name="dontShowAgain"
        value="dont"
        valueChanged={this.setSkipSaveAlertState}
        label={this.props.content.saveDialog.dontShowAgain}
        ariaLabel={this.props.content.saveDialog.dontShowAgain}
      />
    </AlertMessage>
  );

  private renderCancelDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage {...alertProps} />
  );

  private renderErrorDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage
      {...alertProps}
      {...{
        texts: {
          ...alertProps.texts,
          description: this.errorMessage,
        },
      }}
    />
  );

  private logPhotoEditorError = (error: Error) => {
    this.props.logger.appendError("Photo editor error", error);
  };

  private closeFlow = () => {
    const { logger } = this.props;
    const { photo, error } = this.state;

    if (!this.isAvailableClose && !Boolean(error)) {
      logger.appendInfo("Started: Closing uploading flow");

      this.isAvailableClose = true;

      let photoEditorState: PhotoToolState | undefined;
      let updatedPhotoData: Photo | undefined = photo;

      const adminPhotoEditorContainer = this.adminPhotoEditorRef.current;
      const photoEditorContainer = this.photoEditorRef.current;

      try {
        if (photoEditorContainer) {
          photoEditorState = photoEditorContainer.getPhotoEditorState();
        } else if (adminPhotoEditorContainer) {
          photoEditorState = adminPhotoEditorContainer.getPhotoEditorState();

          if (photo) {
            updatedPhotoData = {
              ...photo,
              ...adminPhotoEditorContainer.getPhotoCreditsData(),
            };
          }
        }
      } catch (error) {
        logger.appendError(
          "Error: Getting photo tool state has been failed.",
          error
        );
      }

      let hasChanges = false;

      if (photoEditorState) {
        const { history } = photoEditorState;

        const changeHistory = history
          .getHistoryCopy()
          .map(({ toolState, toolId }) => ({ toolState, toolId }));

        logger.appendInfo("Log: Registering Photo Tool state.", {
          changeHistory,
        });

        hasChanges = history.isEmpty;

        if (history.isEmpty) {
          this.photoEditorState = photoEditorState;
        }
      }

      if (photo && updatedPhotoData) {
        const photoDiff = diff(photo, updatedPhotoData);

        if (photoDiff.length > 0) {
          logger.appendInfo("Log: Pre-saving photo metadata changes.", {
            photoDiff,
          });

          this.updatedPhotoData = updatedPhotoData;
          hasChanges = true;
        }
      }

      if (hasChanges) {
        logger.appendInfo("Called: Moving to cancel step.");
        this.moveToStep(STEP_NAME.cancel);
      } else {
        logger.appendInfo("Called: Closing flow context (no changes).");
        this.props.flowContext.changeContext("", null);
      }
    } else {
      logger.appendInfo("Called: Closing flow context.");
      this.props.flowContext.changeContext("", null);
    }
  };

  public preSavePhoto = (data: PhotoToolState, photo: Photo) => {
    const { logger } = this.props;

    logger.appendInfo("Log: Saving photo state");

    this.photoEditorState = data;
    this.updatedPhotoData = photo;
    const showAlert = getCookie(SKIP_SAVE_ALERT_COOKIE_NAME);

    if (getCookie(SKIP_SAVE_ALERT_COOKIE_NAME)) {
      logger.appendInfo("Called: Confirming of photo saving", { showAlert });

      this.savePhoto();
    } else {
      this.isAvailableClose = true;
      logger.appendInfo("Log: Moving to photo saving confirmation step", {
        showAlert,
      });

      this.moveToStep(STEP_NAME.save);
    }
  };

  private savePhoto = async () => {
    const { globalContext, logger } = this.props;

    if (!this.isProcessStarted) {
      this.isProcessStarted = true;
      this.isAvailableClose = true;
      this.setState({ showContent: false });

      logger.openGroup("photo uploading");

      globalContext.notifyListener(
        GlobalEventTypes.makeVisibleGlobalSpinner,
        true
      );

      const promises: Promise<any>[] = [];

      const { photo } = this.state;

      logger.appendInfo("Started: Updating photo metadata process", {
        updatedPhoto: this.updatedPhotoData,
      });
      if (this.updatedPhotoData && photo) {
        const difference = diff(photo, this.updatedPhotoData);

        logger.appendInfo("Log: Saving photo metadata", { difference });
        if (difference.length > 0) {
          promises.push(PhotoService.updatePhotoMetadata(difference, photo));
        }
      }

      const { history = { isEmpty: true }, builtImage } =
        this.photoEditorState || {};

      logger.appendInfo("Started: Updating photo", {
        isEmpty: history.isEmpty,
      });

      if (!history.isEmpty && builtImage && photo) {
        const changeHistory =
          history.getHistoryCopy &&
          history
            .getHistoryCopy()
            .map(({ toolState, toolId }) => ({ toolState, toolId }));

        logger.appendInfo("Started: Updating photo", { changeHistory });
        promises.push(PhotoService.savePhoto({ ...photo, ...builtImage }));
      }

      try {
        await Promise.all([...promises]);

        logger.appendInfo("Finished: Updating photo metadata process");
        logger.appendInfo("Finished: Updating photo");

        globalContext.notifyListener(GlobalEventTypes.updateMainPhoto, {
          showDialog: false,
        });
        globalContext.notifyListener(GlobalEventTypes.updateGallery);
        this.forceClosePopup();
      } catch (error) {
        logger.appendError("Error: Photo updating has been failed", error);

        this.setState({ showContent: true });
        this.moveToStep(STEP_NAME.error);
      }
    }

    if (this.state.skipSaveAlert) {
      logger.appendInfo("Called: Saving skipSaveAlert flag", {
        value: "true",
        daysNumber: SKIP_SAVE_ALERT_COOKIE_EXPIRY_DAYS,
      });

      setCookie(SKIP_SAVE_ALERT_COOKIE_NAME, "true", {
        expiryTime: SKIP_SAVE_ALERT_COOKIE_EXPIRY_DAYS,
        expiryTimeUnit: ExpiryTimeUnits.DAY,
      });
    }

    this.props.globalContext.notifyListener(
      GlobalEventTypes.makeVisibleGlobalSpinner,
      false
    );
    this.isProcessStarted = true;
  };

  private cancelPhotoEditing = (
    photoToolState?: PhotoToolState,
    photo?: Photo
  ) => {
    const { logger } = this.props;

    if (photoToolState || photo) {
      this.photoEditorState = photoToolState;
      this.isAvailableClose = true;

      if (photo) {
        this.updatedPhotoData = photo;
      }

      const { history = { isEmpty: true } } = photoToolState || {};
      logger.appendInfo("Log: Saving photo state", {
        isHistoryEmpty: history.isEmpty,
        photoMetadata: this.updatedPhotoData,
      });

      if (!history.isEmpty || photo) {
        logger.appendInfo("Log: Moving to cancel step");
        this.moveToStep(STEP_NAME.cancel);
      } else {
        logger.appendInfo("Called: Closing flow");
        this.closeFlow();
      }
    } else {
      logger.appendInfo("Called: Force closing flow");
      this.forceClosePopup();
    }
  };

  private backToPhotoEditing = () => {
    const { logger } = this.props;

    this.isAvailableClose = false;

    logger.appendInfo("Log: Moving to photo edit step");

    this.moveToStep(STEP_NAME.photoEditor);
  };

  private forceClosePopup = () => {
    const { logger } = this.props;
    this.isAvailableClose = true;

    logger.appendInfo("Called: Closing popup (forceClosePopup)");
    this.closeFlow();
  };

  private setSkipSaveAlertState = (isChecked: boolean) =>
    this.setState({ skipSaveAlert: isChecked });

  private showPopup = (message: GlobalAlertMessage) =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );
}

export default compose(
  withGlobalContext,
  withFlowContext,
  withLog(LOG_DOMAIN.photoEdit)
)(PhotoEditingFlow);
