export enum FacetCategories {
  MembershipBodies = "Membership Bodies",
  FacialHair = "Facial Hair",
  VoiceStyle = "Voice Style",
  Appearance = "Appearance",
  HairColour = "Hair Colour",
  EyeColour = "Eye Colour",
  HairLength = "Hair Length",
  Nationality = "Nationality",
  BeltNotes = "Belt Notes",
  Disability = "Disability",
  Gender = "Gender",
  HeritageAndEthnicity = "Heritage And Ethnicity",
  EthnicBackground = "Ethnic Background",
}
