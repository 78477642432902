import avivaLogo from "../../../../../../images/aviva-logo.png";
import markelLogo from "../../../../../../images/markel-logo.png";
import StarIcon from "../../../../../../images/svg/icons/star.svg";
import ShowreelIcon from "../../../../../../images/svg/icons/media.svg";
import PlaneIcon from "../../../../../../images/svg/icons/plane.svg";
import ShieldIcon from "../../../../../../images/svg/icons/shield.svg";
import HmrcIcon from "../../../../../../images/svg/icons/hmrc-logo.svg";
import ProfileDocIcon from "../../../../../../images/svg/icons/profiledoc.svg";
import LinkIcon from "../../../../../../images/svg/icons/link.svg";
import {
  AddOnCodes,
  addOnDescriptionLinkMap,
  addOnDescriptionMap,
  addOnNamesMap,
} from "../../../constants";

export interface IAddOnFeature {
  title: string;
  description?: string;
  icon: any;
  iconAlt?: string;
  additionalIcon?: string;
  additionalIconAlt?: string;
  link?: string;
}

export const getAddOnTexts = (addOnCode: AddOnCodes) => ({
  title: addOnNamesMap[addOnCode],
  description: addOnDescriptionMap[addOnCode],
  descriptionLink: addOnDescriptionLinkMap[addOnCode],
  vatPrice: "common:billing.vat",
  button: {
    selected: "common:button.label.remove",
    unselected: "common:button.label.add",
  },
});

const ecpAddOnMainFeatures: IAddOnFeature[] = [
  {
    title:
      "common:performer.planSelector.addOns.ecp.features.accidentInsurance.title",
    description:
      "common:performer.planSelector.addOns.ecp.features.accidentInsurance.description",
    icon: ShieldIcon,
    iconAlt:
      "common:performer.planSelector.addOns.ecp.features.accidentInsurance.title",
    additionalIcon: avivaLogo,
    additionalIconAlt:
      "common:performer.planSelector.addOns.ecp.features.aviva",
  },
  {
    title:
      "common:performer.planSelector.addOns.ecp.features.taxInsurance.title",
    description:
      "common:performer.planSelector.addOns.ecp.features.taxInsurance.description",
    icon: HmrcIcon,
    iconAlt:
      "common:performer.planSelector.addOns.ecp.features.taxInsurance.title",
    additionalIcon: markelLogo,
    additionalIconAlt:
      "common:performer.planSelector.addOns.ecp.features.aviva",
  },
  {
    title:
      "common:performer.planSelector.addOns.ecp.features.travelInsurance.title",
    description:
      "common:performer.planSelector.addOns.ecp.features.travelInsurance.description",
    icon: PlaneIcon,
    iconAlt:
      "common:performer.planSelector.addOns.ecp.features.travelInsurance.title",
    additionalIcon: avivaLogo,
    additionalIconAlt:
      "common:performer.planSelector.addOns.ecp.features.aviva",
  },
];

const addOnSubFeatures = [
  {
    title:
      "common:performer.planSelector.addOns.ecp.features.subFeatureAdditional",
    features: [
      {
        title: "common:performer.planSelector.addOns.ecp.features.media.title",
        description:
          "common:performer.planSelector.addOns.ecp.features.media.description",
        icon: ShowreelIcon,
        iconAlt:
          "common:performer.planSelector.addOns.ecp.features.media.title",
      },
    ],
  },
  {
    title: "common:performer.planSelector.addOns.ecp.features.subFeaturePlus",
    features: [
      {
        title: "common:performer.planSelector.addOns.ecp.features.events.title",
        description:
          "common:performer.planSelector.addOns.ecp.features.events.description",
        icon: StarIcon,
        iconAlt:
          "common:performer.planSelector.addOns.ecp.features.events.title",
        link: "https://www.spotlight.com/uncategorized/spotlight-performer-events",
      },
    ],
  },
];

const filmMakersMainFeatures = [
  {
    title: "common:performer.planSelector.addOns.fm.features.profile.title",
    description:
      "common:performer.planSelector.addOns.fm.features.profile.description",
    icon: ProfileDocIcon,
    iconAlt: "common:performer.planSelector.addOns.fm.features.profile.title",
  },
  {
    title: "common:performer.planSelector.addOns.fm.features.profileLink.title",
    description:
      "common:performer.planSelector.addOns.fm.features.profileLink.description",
    icon: LinkIcon,
    iconAlt:
      "common:performer.planSelector.addOns.fm.features.profileLink.title",
  },
];

interface IFeaturesType {
  mainFeatures: IAddOnFeature[];
  subFeatures: {
    title: string;
    features: IAddOnFeature[];
  }[];
}

export const ecpAddOnFeatures: IFeaturesType = {
  mainFeatures: ecpAddOnMainFeatures,
  subFeatures: addOnSubFeatures,
};

export const filmMakersAddOnsFeatures: IFeaturesType = {
  mainFeatures: filmMakersMainFeatures,
  subFeatures: [],
};

export const features = {
  [AddOnCodes.AnnualBundle]: ecpAddOnFeatures,
  [AddOnCodes.MonthlyBundle]: ecpAddOnFeatures,
  [AddOnCodes.FilmMakersAnnual]: filmMakersAddOnsFeatures,
  [AddOnCodes.FilmMakersMonthly]: filmMakersAddOnsFeatures,
};

export const BASE_ANNUAL_TEXTS = {
  title: "common:billing.addOn.planSelector.annual.title",
  period: "common:label.year",
  badgeText: "common:billing.label.deal",
  button: {
    selected: "common:button.label.selected",
    unselected: "common:button.label.choose",
  },
};

export const BASE_MONTHLY_TEXTS = {
  title: "common:billing.addOn.planSelector.monthly.title",
  period: "common:label.month",
  button: {
    selected: "common:button.label.selected",
    unselected: "common:button.label.choose",
  },
};

export const injectPlanButtonLabel = (texts: Record<string, any>) => {
  return {
    ...texts,
    button: {
      ...texts.button,
      selected: "common:button.label.existingSubscription",
    },
  };
};
